#a-login-div {
  margin-right: 1em;
  text-align: right;
  font-size: 11.5pt;
  font-weight: bold;
  font-family: "Dejavu Sans", "helvetica neue", aria, sans-serif;
}
.indent {
  margin-left: 1em;
}
body {
  /* background: aquamarine; */
  margin: 0px auto;
  max-width: 1200px;
  user-select: none;
  -webkit-user-select: none;
  /* min-width: 600px; */
  /* width: fit-content; */
}
.light {
  background: #bada55;
}
.dark {
  background: rgb(60, 73, 78);
}
hr {
  border-style: dashed;
  display: none;
}
h2 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}
pre {
  overflow-wrap: break-word;
  overflow: auto;
}
div {
  overflow-wrap: break-word;
  --text-align: left;
}
a {
  text-decoration: none;
  /* color: fuchsia; */
}
a:link {
  color: purple;
}

a:hover {
  color: fuchsia;
}

a:active {
  color: green;
}

a.tag {
  background-color: #fffcd7;
  border: 1px solid #d5d458;
  border-radius: 5px;
  color: #555;
  font-size: 10pt;
  margin-left: 0.25em;
  padding: 0px 0.4em 1px 0.4em;
  text-decoration: none;
  vertical-align: text-top;
}

#cover {
  /* width: 100%; */
  width: auto;
  /* max-height:300px; */
  /* max-width: 600px; */
}

#main {
  /* min-width: 600px; */
  /* max-width: 800px; */
  /* width: 800px; */
}
#view {
  position: relative;
}
#topbar {
  display: block;
}
#docs {
  margin-left: 1em;
  margin-right: 1em;
}
.doc {
  display: block;
  color: grey;
  text-align: left;
  font-size: 11.5pt;
  font-weight: bold;
  font-family: "Dejavu Sans", "helvetica neue", aria, sans-serif;
  background: white;
  border-width: thin;
  /* border-color: black; */
  /* border-style: solid; */
  border-radius: 1em;
  /* margin-left: 1em; */
  /* margin-right: 1em; */
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  /* box-shadow: 3px 3px 13px 0px black; */
}
.help {
  float: right;
}
.tit {
  padding-left: 8%;
}
#controller {
  display: none;
}
.auth {
  background: #5ec2e7;
}
.noauth {
  background: #bada55;
}
.agent {
  background: white;
  border-width: thin;
  /* border-color: black; */
  /* border-style: solid; */
  position: relative;
  border-radius: 1em;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em;
  /* box-shadow: 3px 3px 13px 0px black; */
}
.agent-right {
  cursor: pointer;
  float: right;
  position: absolute;
  border-color: gray;
  border-right-style: dashed;
  font-family: "Dejavu Sans", "helvetica neue", aria, sans-serif;
  border-width: thin;
  top: 0px;
  bottom: 0px;
  width: 2em;
  right: 0px;
  border-bottom-right-radius: 1em;
  border-top-right-radius: 1em;
  /* background-color: peachpuff; */
  /* background: #bada55; */
  text-align: center;
}
.agent-right-logo {
  position: relative;
  margin-left: 0.7em;
  top: 36%;
}
.agent-left {
  margin-right: 2em;
}
.agent-topbar {
  font-family: "Dejavu Sans Mono", "helvetica neue", aria, sans-serif;
  font-size: 11.5pt;
  font-weight: bold;
  line-height: 1.45em;
  margin-bottom: 0.25em;
}
.agent-midbar {
  font-family: "Dejavu Sans Mono", "helvetica neue", aria, sans-serif;
  font-size: 11.5pt;
  font-weight: bold;
  line-height: 1.45em;
  margin-bottom: 0.55em;
}
.agent-subbar {
  font-family: "Dejavu Sans", "helvetica neue", aria, sans-serif;
  font-size: 10.5pt;
  line-height: 0.85em;
  color: #888;
}
.legend {
  width: 2em;
  height: 2em;
  background: black;
}

.foo {
  float: left;
  width: 20px;
  height: 20px;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.bar {
  float: left;
  /* width: 8em; */
  height: 20px;
  margin: 5px;
  /* border: 1px solid rgba(0, 0, 0, .2); */
}

.badass {
  background: #bada55;
}

.secret {
  background: #5ec2e7;
}

.foobar {
  position: relative;
  min-width: 15em;
  max-width: 18em;
  width: 40%;
  float: left;
  margin-left: 1em;
}

fieldset {
  /* border-style: solid; */
  border-radius: 1em;
  border-color: pink;
  margin-bottom: 1em;
}

a.disabled {
  opacity: 0.25;
}
