/* Copyright 2020 The LUCI Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.lucy-logo {
  width: 4em;
  float: left;
  margin-right: 10px;
}

.project-favicon-text {
  min-width: 108px;
  height: 108px;
  text-align: center;
  font-size: 40pt;
  padding-top: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.project a:link {
  text-decoration: none;
}

.project a:visited {
  text-decoration: none;
}

.project a:hover {
  text-decoration: none;
}

.project a:active {
  text-decoration: none;
}

.project img {
  max-width: 110px;
  height: 110px;
  padding: 4px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  display: block;
}

.project:hover {
  background: #cce;
}

.project {
  font-size: 10pt;
  min-width: 120px;
  flex: 1;
  height: 160px;
  background-color: gray;
  padding: 0.4em;
  background: #ddd;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 18px;
}

.project-label {
  margin-bottom: 8px;
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.project {
  font-size: 10pt;
}

.project-list {
  display: flex;
  column-gap: 0.6rem;
  row-gap: 0.6rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
